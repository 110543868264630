.header {
}

.logo {
  height: 2rem;
  cursor: pointer;
}

.menuItem {
  padding: 0.2rem 0.2rem;
  color: black;
}

.menuItem:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  color: black;
  cursor: pointer;
}

.activeMenuItem {
  padding: 0.2rem 0.2rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  background-color: rgb(74, 199, 128);
  border-radius: 5px;
  color: white;
}

.header .lessonsButton {
  width: fit-content;
  border-radius: 15px;
  background-color: #2a2a2a; 
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.2s;
}

.header .lessonsButton:hover {
  background-color: black;
  transform: scale(1.02);
}

.header .lessonsText {
  width: fit-content;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
}

.header .hamburgerMenuItems {
  transition: background-color 0.3s ease;
  border-top: 2px solid black;
}

.header .hamburgerMenuItems:hover {
  background-color: rgb(0, 189, 47);
}

.activeHamburgerMenuItems h6 {
  text-decoration: underline;
}

.hamburgerMenuItemText h6 {
  font-weight: 600;
  color: white;
  padding: 0.5rem;
  text-align: left;
}


body .lessonContainer {
  height: 100%;
  background-color: #1ba464;
  display: flex;
  flex-direction: column;
}

body .lessonDrawer {
  padding: 20px;
  background-color: #1ba464;
  overflow: scroll;
  border-bottom: 2px solid black;
}

.lessonHeader {
  text-align: center;
  color: white;
}

.lessonItem {
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}

.lessonItem:hover {
  transform: scale(1.02);
}

.activeLessonItem {
  background-color: #a4ff7b;
}

.lessonContent {
  padding: 16px;
  width: 100%;
}

.quizItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
}

.quizStatus {
  font-weight: 500;
  color: #333;
}

.quizScore {
  text-align: center;
}

.lessonTitle {
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
}

.lessonDescription {
  margin-bottom: 12px;
  color: #666;
}

.lessonProgressContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.lessonProgress {
  height: 8px;
  border-radius: 4px;
  flex: 1;
}

.lessonProgress .MuiLinearProgress-bar {
  background-color: #0a6d0e;
}

.lessonPercentage {
  min-width: 40px; 
  text-align: right;
}

.hamburgerContainer {
}

@media (max-width: 768px) {
  .header .lessonsText h6{
    font-size: 1rem;
    letter-spacing: 0;

  }
}