.lesson__small {
  min-height: 100vh;
  background-color: #18191a;
}

.lesson__large {
  height: 100vh;
}

.lesson__main {
  position: relative; /* Ensures positioning context for absolutely positioned elements */
  display: flex; /* Enables a flexible layout with the sidebar and content next to each other */
}

.lesson__content {
  flex-grow: 1; /* Allows the content to take up the remaining space */
  margin-left: 0; /* No margin needed as the sidebar is overlaid */
  width: 100%; /* Full width since sidebar is not shifting it */
  height: calc(100vh - 4rem); /* Full height of the viewport minus the header height */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  transition: margin-left 0.3s ease; /* Smooth transition for content when sidebar is toggled */
}

.video__note {
  display: flex;
}

@media not all and (min-resolution: 0.001dpcm) {
  body .login {
    height: -webkit-fill-available;
  }
}

/* The side navigation menu */
.header {
  width: 100%;
  background: linear-gradient(136deg, #008b81, #368a59, #fff);
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 20;
  justify-content: space-between;
  position: relative;
}

.header__small {
  height: 4rem;
  width: 100%;
  background: linear-gradient(136deg, #008b81, #368a59, #fff);
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 30;
  justify-content: space-between;
}

.header__logo {
  margin: auto;
  height: auto;
  width: 4rem;
  object-fit: cover;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.header__lesson {
  text-align: center;
  font-family: 'Helvetica Neue', 'Source Sans Pro';
  font-weight: 700;
  color: floralwhite;
  width: 100%;
  padding: 0.5rem 0;
  padding-right: 0.5rem;
}

.header__list {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  height: 4rem;
  align-items: center;
  justify-content: flex-end;
}

.header__top__center {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  /* position: absolute;
  left: calc(50vw - 400px/2); */
}

.header__item {
  padding: 0 1rem;
  color: #002d62;
  font-family: 'Helvetica Neue', 'Source Sans Pro';
  font-size: 1.1rem;
  font-weight: 900;
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 2;
}

.header__item:hover {
  color: seagreen;
  cursor: pointer;
}

.header__next__previous {
  color: white;
  cursor: pointer;
  font-size: 2rem;
}

.header__next__previous.inactive {
  color: transparent;
  cursor: default;
  font-size: 2rem;
}

.header__lessons__selector {
  margin: 0 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header__lessons {
  color: white;
  font-family: 'Helvetica Neue', 'Source Sans Pro';
  font-size: 1.1rem;
  text-decoration: none;
  font-weight: 700;
}

.emptyContainer {
  height: 60vw;
}

/* @media screen and (max-width: 767px) {
  .header__lesson:hover {
    background-color: transparent;
  }
} */

/* .header__changeLesson {
  width: 100%;
} */

/* .features {
  margin-top: 20vh;
  color: floralwhite;
}

.header__features {
  margin: auto;
  font-family: "Helvetica Neue", "Source Sans Pro";
  font-weight: 700;
  margin-bottom: 4vh;
  display: flex;
  justify-content: space-evenly;
}

.header__features p {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 5px;
  font-size: 1.1rem;
} */

/* .fa-xl {
  line-height: 1;
} */

/* .header__features:hover {
  background-color: rgb(207, 208, 209);
  border-radius: 2px;
  height: fit-content;
  cursor: pointer;
} */

/* header links */
/* .header a {
  display: block;
  color: black;
  text-decoration: none;
  width: 100%;
} */

/* Active/current link */
/* .header a.active {
  background-color: #04AA6D;
  color: white;
} */

/* .header__menu {
  display: flex;
  flex-direction: row;
  height: 4rem;
} */

.header__icon {
  font-size: 2rem;
  padding-right: 7px;
  cursor: pointer;
}

.buddy__icon__container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  background: #0089a3;
}

.buddy__icon {
  max-height: 50px;
}

.buddy__icon:hover {
  content: url('https://swc-static-images.s3.amazonaws.com/miscellaneous/buddy2.png');
  cursor: pointer;
}

.buddy__icon__container:hover + .buddy__helpertext {
  display: block;
}

.buddy__helpertext {
  position: fixed;
  bottom: 80px;
  right: 0px;
  text-align: center;
  box-sizing: border-box;
  border: 3px solid #0089a3;
  border-radius: 10px;
  background-color: #fff3fe;
  display: none;
}

.buddy__helpertext:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 84%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: #0089a3;
  border-bottom: 0;
  margin-left: -20px;
  margin-bottom: -20px;
}

.buddy__container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: rgb(18, 18, 18);
  border: 2px solid seagreen;
  border-radius: 20px;
  padding: 32px;
  font-family: 'Helvetica Neue', 'Source Sans Pro';
  color: white;
}

.buddy__container__input {
  text-align: center;
}

.buddy__container__input > textarea {
  width: 70%;
  height: 100px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Helvetica Neue', 'Source Sans Pro';
}

.buddy__submit {
  outline: 0;
  background: white;
  border: 0;
  border-radius: 20px;
  padding: 15px;
  color: black;
  font-size: 1rem;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
  transition: all 0.2s;
}

.buddy__submit:hover {
  background-color: seagreen;
  color: white;
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 1000px) {
  .buddy__icon {
    height: 80px;
  }

  .buddy__container {
    width: 80%;
    max-height: 80%;
    overflow-y: auto;
  }

  .buddy__container__input > textarea {
    width: 100%;
  }
}

/* Page content. The value of the margin-left property should match the value of the sidebar's width property
div.content {
  height: calc(100vh - 4rem);
} */

.topMenu {
  width: 100vw;
  height: fit-content;
  position: absolute;
  top: -326px;
  left: 0;
  background-color: seagreen;
  transition: 0.25s;
  z-index: 10;
}

.outsideHeader {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.5;
  z-index: 9;
}

.outsideHeader.active {
  display: block;
}

.topMenu__changeLesson {
  display: flex;
  justify-content: center;
}

.topMenu.active {
  top: 64px;
}

.topMenu__icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  font-size: 2rem;
}

.signout {
  margin-left: 0.5rem;
}

/* On screens that are less than 767px wide, make the header into a topbar */
@media screen and (max-width: 1000px) {
  .header {
    width: 100%;
    height: auto;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
  }

  /* .header a {
    width: auto;
    float: left;
    padding: 0px;
  } */
}

/* On screens that are less than 400px, display the bar vertically, instead of horizontally */
/* @media screen and (max-width: 400px) {
  .header a {
    text-align: center;
    float: none;
  }
} */

.lesson-select {
  /* styling */
  background-color: rgb(255, 255, 255);
  border: thin solid blue;
  border-radius: 4px;
  display: block;
  margin: 0 auto;
  text-align: center;
  font: inherit;
  line-height: 1.5em;
  text-overflow: ellipsis;
  width: inherit;
  font-family: 'Helvetica Neue', 'Source Sans Pro';
  outline: none;
  width: 20rem;

  /* reset */
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

@media screen and (max-width: 1000px) {
  .lesson-select {
    width: fit-content;
    padding: 0 30px;
  }

  .header__item:hover {
    background-color: transparent;
  }
}

/* arrows */
select {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%), radial-gradient(#ddd 70%, transparent 72%);
  background-position: calc(100% - 12px), calc(100% - 7px), calc(100%);
  background-size:
    5px 5px,
    5px 5px,
    1.5em 1.5em;
  background-repeat: no-repeat;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.tabs {
  display: flex;
  justify-content: center;
}

.tab--btn {
  flex-grow: 1;
  font-family: "Roboto","Helvetica","Arial",sans-serif; 
  /* letter-spacing: 3px; */
  border: none;
  padding: 10px;
  background-color: white;
  color: black;
  font-size: 1rem;
  transition: 0.25s;
}

.tab--btn.active {
  background-color: #87ebb3;
  font-weight: bolder;
}

.tab--content {
  display: none;
  background-color: white;
}

.tab--content.active {
  display: block;
}
