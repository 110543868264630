.doodle {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw; /* Make sure it covers the entire parent container */
    height: 100vh; /* Make sure it covers the entire parent container */
    z-index: -5;
    /* background: linear-gradient(rgba(248, 248, 255, 1) 0%, rgb(183 255 151) 150%); */
    background-color: ghostwhite;
  }

.hero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding: 20px;
  max-width: 1400px;
}

.hero-content {
  max-width: 50%;
  text-align: center;
}

.hero-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.hero-description {
  margin-bottom: 30px;
}

.join-button {
    border-radius: 16px;
    margin-top: 5%;
    padding: 16px 32px;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    border: 0;
    color: white;
    background: #368a59;
    box-shadow:
      0px 78px 22px 0px rgba(132, 85, 246, 0),
      0px 50px 20px 0px rgba(132, 85, 246, 0.01),
      0px 28px 17px 0px rgba(132, 85, 246, 0.05),
      0px 12px 12px 0px rgba(132, 85, 246, 0.09),
      0px 3px 7px 0px rgba(132, 85, 246, 0.1);
    transition: background-color 0.3s ease;
    transition: all 0.2s;
  }
  
  .join-button:hover {
    background-color: #008b81;
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

.hero-buttons {
  display: flex;
  flex-direction: column;
}

.get-started-button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 10px;
}

.hero-image {
  max-width: 50%;
}

.hero-image img {
  max-width: 100%;
  transition: transform 0.3s ease;
}

.hero-image img:hover {
  transform: scale(1.05);
}

@media (max-width: 767px) {
  .hero-container {
    flex-direction: column;
    text-align: center;
  }

  .hero-content {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .hero-title {
    font-size: 2rem;
  }

  .hero-buttons {
    align-items: center;
  }

  .hero-image {
    max-width: 100%;
  }

  .hero-image img {
    width: 100%;
  }
}
