button {
  cursor: pointer;
}

.comment--section {
  padding: 10px 15px;
  font-family: 'Helvetica Neue', 'Source Sans Pro';
  display: flex;
  flex-direction: column;
  background: #18191a;
}

.add-code-button {
  border: 1px solid white;
  background: transparent;
  color: white;
  border-radius: 8px;
  padding: 4px 8px;
  margin: 16px 0 8px 0;
}

.add-code-button:hover {
  background: white;
  color: #18191a;
}

.format-code-button {
  border: none;
  border-radius: 8px;
  background: #0089a3;
  color: black;
  padding: 4px 8px;
  margin: 16px 0 8px 0;
}

.code-block-errors {
  color: #b23131;
  display: block;
}

.code-snippet {
  color: white;
  padding: 8px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 8px;
}

.code-snippet label {
  font-weight: bold;
}

.code-snippet select {
  border-radius: 8px;
  margin-left: 8px;
  margin-bottom: 8px;
  background: transparent;
  color: white;
  padding: 2px 4px;
}

.code-snippet option {
  color: black;
}

.add-code-comment {
  background: #368a59;
  font-weight: bold;
  padding: 4px 8px;
  margin-top: 8px;
  margin-right: 8px;
  border: none;
  border-radius: 8px;
}

.cancel-code-button {
  background: transparent;
  font-weight: bold;
  padding: 4px 8px;
  color: white;
  border: none;
}

.add-code-comment:hover,
.format-code-button:hover {
  opacity: 0.8;
}

.cancel-code-button:hover {
  text-decoration: underline;
}

.faRotateRight {
  position: absolute;
  color: gray;
  right: 10px;
  cursor: pointer;
}

.comments--count {
  color: white;
  font-size: 1.2rem;
}

.comment--section--header {
  display: flex;
  align-items: center;
}

.comment--section--header .faSort {
  padding: 0;
  margin-left: 1rem;
  background-color: transparent;
  color: grey;
  border: 0;
  font-family: 'Helvetica Neue', 'Source Sans Pro';
  font-size: 0.8rem;
  align-self: center;
}

.faSortOrder {
  padding: 0;
  background-color: transparent;
  color: grey;
  border: 0;
  font-family: 'Helvetica Neue', 'Source Sans Pro';
  font-size: 0.8rem;
  align-self: center;
  align-items: center;
}

.comment--section--header select {
  background-color: transparent;
  color: white;
  cursor: pointer;
  border: 0;
  font-family: 'Helvetica Neue', 'Source Sans Pro';
  font-size: 0.8rem;
  height: fit-content;
  align-self: center;
  transition: 0.4s;
  padding-left: 5px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: none;
}

.comment--section--header select:focus {
  outline: 0;
}

.comment--section--header select:hover {
  background-color: gray;
}

.comment--section--header select option {
  color: black;
  background-color: white;
}

.add--comment--form {
  padding: 5px 0 10px 0;
}

.code-block {
  min-height: 20px !important;
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  border-radius: 8px;
  padding: 8px;
  white-space: pre-wrap;
  word-break: break-word;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.05);
  box-sizing: border-box;
  resize: none;
  color: white;
}

.text-block-area {
  min-height: 25px !important;
  padding: 5px;
  margin-bottom: 5px;
  background-color: transparent;
  color: white;
  overflow: hidden;
  transition: 0.4s;
  font-size: 0.8rem;
  box-sizing: border-box;
  resize: none;
  width: 100%;
  border: 0;
  border-bottom: 1px gray solid;
}

.text-block-area:focus {
  border-bottom: 1px white solid;
  outline: 0;
}

.add--comment--form input {
  margin-left: 5px;
}

.add--comment--form .cancel--inactive {
  display: none;
}

.add--comment--form .cancel--active {
  display: block;
  border-radius: 15px;
  border: 0;
  padding: 5px 10px;
  float: right;
  background-color: transparent;
  color: white;
  cursor: pointer;
}

.add--comment--form .cancel--active:hover {
  background-color: darkgray;
}

.add--comment--form .submit--inactive {
  display: none;
}

.add--comment--form .submit-temp-inactive {
  border-radius: 15px;
  border: 0;
  padding: 5px 10px;
  float: right;
  background-color: grey; /* Grey background when focused and text is empty */
  color: white; /* White text color for visibility */
  pointer-events: none; /* Disable clicking */
  transition: 0.5s; /* Smooth transition */
}

.add--comment--form .submit--active {
  border-radius: 15px;
  border: 0;
  padding: 5px 10px;
  float: right;
  background-color: lightskyblue;
  color: black;
  cursor: pointer;
}

.expand--reply .replies--list {
  display: none;
}

.expand--reply.active .replies--list {
  display: block;
  padding: 10px;
  margin-left: 21px; /* Aligns with the indentation */
  border-radius: 15px; /* Ensure the border radius matches */
}

.show--reply {
  color: deepskyblue;
}

.expand--reply .show--reply {
  padding: 5px 10px;
  background-color: transparent;
  border-radius: 15px;
  cursor: pointer;
  border: 0;
  font-family: 'Helvetica Neue', 'Source Sans Pro';
  font-size: 0.8rem;
  color: deepskyblue;
  width: fit-content;
  /* margin-bottom: 5px; */
}

.reply--form--active {
  display: block;
  padding-left: 10px;
}

.reply--form--inactive {
  display: none;
}

.comment--and--reply {
  background-color: #2a2a2a; /* Change background*/
  border-radius: 15px; /* More rounded corners */
  padding: 10px; /* Spacing around content */
  margin-bottom: 15px; /* Space between comments */
}

.comment {
  display: grid;
  grid-template-columns: 1fr;
}

.comment--header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 5px;
}

.comment--name {
  color: #eedc82;
  font-size: 0.9rem;
  font-weight: bold;
}

.comment--role {
  margin-left: 5px;
  padding: 2px 9px 2px 5px;
  font-size: 0.8rem;
  font-style: italic;
  color: white;
  border-radius: 15px 3px 15px 3px;
  font-weight: 600;
}

.comment--role.swcadmin {
  background: linear-gradient(90deg, #fc466b 0%, #3f5efb 100%);
}

.comment--role.orgadmin {
  background: linear-gradient(90deg, #1cb5e0 0%, #4b6cb7 100%);
}

.comment--role.teacher {
  background: linear-gradient(90deg, #c67700 0%, #22c1c3 100%);
}

.comment--date {
  padding-left: 5px;
  font-size: 0.8rem;
  color: grey;
}

.comment--delete {
  display: none;
}

.comment:hover .comment--delete {
  display: block;
  cursor: pointer;
  font-size: 0.8rem;
  color: grey;
  align-self: flex-end;
}

.comment:hover .comment--delete:hover {
  color: darkgrey;
}

.reply {
  padding: 5px;
  border-radius: 5px;
}

.reply--delete {
  display: none;
}

.reply:hover .reply--delete {
  display: block;
  cursor: pointer;
  font-size: 0.8rem;
  color: grey;
  align-self: flex-end;
}

.reply:hover .reply--delete:hover {
  color: darkgrey;
}

.comment--content {
  color: white;
  margin-bottom: 5px;
  white-space: pre-wrap;
  font-size: 0.9rem;
  overflow-wrap: break-word;
}

.comment--content .code {
  margin-top: auto;
  margin-bottom: 0;
}

.comment--footer {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.upvote {
  color: white;
  cursor: pointer;
}

.upvote--count {
  color: white;
  padding-left: 5px;
  font-size: 0.8rem;
}

.comment--footer button {
  padding: 0;
  padding-left: 15px;
  background-color: transparent;
  border-radius: 15px;
  cursor: pointer;
  border: 0;
  font-family: 'Helvetica Neue', 'Source Sans Pro';
  font-size: 0.8rem;
}

.comment--footer .reply--btn {
  color: deepskyblue;
}

.confirm--box--container {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 19;
}

.confirm--box {
  font-family: 'Helvetica Neue', 'Source Sans Pro';
  border-radius: 15px;
  background-color: rgba(105, 105, 105, 1);
  padding: 20px;
  text-align: right;
  z-index: 20;
}

.confirm--box div {
  color: white;
  padding: 10px 10px 20px 10px;
}

.confirm--box button {
  color: lightskyblue;
  border-radius: 15px;
  padding: 10px;
  cursor: pointer;
  border: 0;
  background-color: transparent;
}

.confirm--box button:hover {
  background-color: #87cefa80;
}

[contenteditable='true']:empty:not(:focus):before {
  content: attr(data-placeholder);
  color: #aaa;
}

/* .delete--confirm {
    font-family: "Helvetica Neue", "Source Sans Pro";
    border-radius: 15px;
    background-color: DimGray;
    padding: 20px;
    text-align: right;
}

.delete--confirm div {
    color: white;
    padding: 10px 10px 20px 10px;
}

.delete--confirm button {
    color: lightskyblue;
    border-radius: 15px;
    padding: 10px;
    cursor: pointer;
    border: 0;
    background-color: transparent;
}

.delete--confirm button:hover {
    background-color: #87CEFA80;
} */

@media screen and (max-width: 767px) {
  .comment--section--header select:hover {
    background-color: transparent;
  }

  .add--comment--form .cancel--active:hover {
    background-color: transparent;
  }

  .show--reply:hover {
    background-color: transparent;
  }

  .comment:hover .comment--delete {
    color: grey;
  }

  .comment:hover .comment--delete:hover {
    color: grey;
  }

  .reply:hover .reply--delete {
    color: grey;
  }

  .reply:hover .reply--delete:hover {
    color: grey;
  }

  .delete--confirm button:hover {
    background-color: transparent;
  }
}

.highlight {
  animation-name: highlight;
  animation-duration: 3s;
}

@keyframes highlight {
  from {
    background-color: rgba(137, 207, 240, 0.5);
  }

  to {
    background-color: initial;
  }
}
