.footer {
  display: flex;
  background: #0d0918;
  color: #faf9ff;
  justify-content: space-between;
  padding: 56px 64px;
  font-family: Inter, sans-serif;
  align-items: center;
  border-top: 3px solid #008b81;
  margin-top: 50px;
}

.footer h5 {
  font-size: 28px;
  font-weight: bold;
  margin: 0 0 12px 0;
}

.footer h6 {
  font-size: 20px;
  font-weight: 100;
  margin: 0 0 32px 0;
}

.cta-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.cta-buttons > button {
  border-radius: 16px;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
}

.cta-buttons > .join-button {
  border: 0;
  color: white;
  background: #368a59;
  box-shadow:
    0px 64px 18px 0px rgba(54, 138, 89, 0),
    0px 41px 16px 0px rgba(54, 138, 89, 0.01),
    0px 23px 14px 0px rgba(54, 138, 89, 0.05),
    0px 10px 10px 0px rgba(54, 138, 89, 0.09),
    0px 3px 6px 0px rgba(54, 138, 89, 0.1);
  transition: all 0.2s;
}

.cta-buttons > .join-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.2);
  background-color: #008b81;
  color: black;
  filter: brightness(150%);
}

.cta-buttons > .about-button {
  background: transparent;
  color: inherit;
  border: 1px solid #faf9ff;
  transition: all 0.2s;
}

.cta-buttons > .about-button:hover {
  background: #faf9ff;
  color: #0d0918;
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.2);
}

.contact-us h5 {
  text-align: center;
}

.contact-icons {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.contact-icons > a {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}

.contact-icons > a:hover {
  opacity: 0.75;
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.2);
}

@media screen and (max-width: 600px) {
  .footer {
    padding: 32px 16px;
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .footer {
    padding: 64px 64px;
  }
}

@media screen and (max-width: 900px) {
  .footer {
    flex-direction: column;
  }

  .contact-us {
    margin-top: 64px;
  }

  .cta-buttons {
    justify-content: center;
  }
}
