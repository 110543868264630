body .groupContainer {
  gap: 0;
  padding-bottom: 0;
}

body .groupContent {
  padding: 1rem;
}

body .groupTitle {
  color: #139250;
  padding-bottom: 1rem;
}

body .groupDescription {
  text-align: left;
  padding-bottom: 1rem;
}

body .groupLessonProgressContainer {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
}

body .groupLessonProgress {
  height: 8px;
  border-radius: 4px;
  flex: 1;
}

body .groupLessonPercentage {
  min-width: 40px; 
  text-align: right;
  padding-left: 1rem;
}

.groupButton {
  width: 100%;
}