.courses {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: linear-gradient(135deg, #271034 40%, #2ecc71);
}

/* Background Doodle Styling */
.doodle {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

@media not all and (min-resolution: 0.001dpcm) {
  body .courses {
    height: -webkit-fill-available;
  }
}

@media screen and (min-resolution: 192dpi) {
  body .course {
    height: -webkit-fill-available;
  }
}

.courses--content {
  position: relative;
  z-index: 100;
  width: 100%;
  height: 100%;
  font-family: 'Helvetica Neue', 'Source Sans Pro';
  font-size: 4rem;
  font-weight: bold;
  color: white;
  text-align: center;
}

.courses--courselist {
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, 350px);
  grid-gap: 1rem;
}

@media only screen and (max-width: 600px) {
  .courses--courselist {
    grid-template-columns: repeat(1, 350px);
  }
  .courses--content {
    width: 100vw;
  }
}
