.video {
    position: relative;
    background-color: #18191a;
    height: calc(100vh - 4rem);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: auto;
}

.video::-webkit-scrollbar {
    width: 7.5px;
}

.video::-webkit-scrollbar-track {
    background: transparent;
}

.video::-webkit-scrollbar-thumb {
    background: darkgray;
    border-radius: 15px;
}

.video::-webkit-scrollbar-thumb:hover {
    background: gray;
}

/* .video__player {
    position: absolute;
    top: 0;
    left: 0;
} */

/* .video iframe {
    width: 90% !important;
    height: 50% !important;
} */


.video__container {
    position: relative;
    width: 100%;
  }
  
  .video__nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .video__nav-button:hover {
    background-color: rgba(0, 0, 0, 1);
  }
  
  .video__nav-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .video__nav-button--prev {
    left: 10px;
  }
  
  .video__nav-button--next {
    right: 10px;
  }

  @media screen and (max-width: 600px) {
    .video {
        height: fit-content;
    }

    .video__container {
      position: fixed;
    z-index: 999;
    width: 100%;
    }
}