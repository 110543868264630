body {
  font-family: var(--font-family);
  margin: 0;
  padding: 0;
}



.admin {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  background-position: center;
}

.admin::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://swc-static-images.s3.amazonaws.com/background/background_green-code.png');
  background-size: cover;
}

@media not all and (min-resolution: 0.001dpcm) {
  body .courses {
    height: -webkit-fill-available;
  }
}

@media screen and (min-resolution: 192dpi) {
  body .course {
    height: -webkit-fill-available;
  }
}

.header-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: fit-content;
  z-index: 1000;
}


.admin--content {
  --font-family: 'Helvetica Neue', 'Source Sans Pro', sans-serif;
  --font-size-medium: 1.2rem;
  --font-size-small: 1rem;
  --transition-duration: 0.3s;
  margin-top: 125px;
  position: relative;
  z-index: 100;
  width: 1400px;
  height: fit-content;
  font-family: 'Helvetica Neue', 'Source Sans Pro';
  font-size: 4rem;
  font-weight: bold;
  color: white;
  text-align: center;
  margin-bottom: 1rem;
}

.admin--list {
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-gap: 1rem;
}


.item:hover,
.user:hover {
  background-color: #435db8;
  color: white;
}

.item, .user {
  box-shadow: 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  border: 3px solid black;
  cursor: pointer;
  height: 90px;
  background-color: white;
  color: black;
  padding: 2rem 1rem;
  font-size: var(--font-size-medium);
  text-align: center;
  font-family: var(--font-family);
  font-weight: bold;
  word-break: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color var(--transition-duration), color var(--transition-duration);
}

.nav--bar {
  background-color: transparent;
  font-size: 1.5rem;
  color: white;
  text-align: left;
  margin-bottom: 2rem;
  padding: 10px 40px;
  display: flex;
  border-top: 1px white solid;
  border-bottom: 1px white solid;
}

.nav--bar .not--active {
  border-radius: 10px;
  padding: 5px 10px;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color var(--transition-duration), color var(--transition-duration);
}

.nav--bar .not--active:hover {
  background-color: #0066b2;
  color: white;
}

.nav--bar .active {
  padding: 5px 10px;
  margin: 0 10px;
  cursor: default;
  background-color: #0066b2;
  border-radius: 10px;
}

.nav--bar .fa-chevron-right {
  align-self: center;
}

@media only screen and (max-width: 767px) {
  .admin--content {
    width: 100vw;
  }

  .admin--list {
    grid-template-columns: repeat(1, 300px);
  }

  .nav--bar {
    flex-direction: column;
    margin: 0 auto 1rem;
    font-size: var(--font-size-medium);
    width: 300px;
    text-align: center;
  }

  .fa-chevron-right {
    transform: rotate(90deg);
  }

  .user {
    font-size: var(--font-size-small);
  }
  .key {
    font-size: 0.9rem;
  }
  .value {
    font-size: 0.8rem;
  }
  .lesson--name {
    font-size: 0.9rem;
  }
  .category {
    font-size: 1rem;
    margin-left: 0;
    text-align: center;
  }
  .category--tooltip {
    justify-content: center;
  }
  .tableHelper {
    font-size: 0.9rem;
  }
}

.info--list {
  width: fit-content;
  margin: auto;
  background-color: white;
  border-radius: 15px;
  padding: 10px;
  box-shadow: 2px 5px black;
  border: 2px solid black;
}

.info--list table td {
  border: none;
}

.key {
  font-size: 1rem;
  color: grey;
}

.value {
  font-size: 1rem;
  color: black;
}

.info {
  background-color: white;
  text-align: left;
}

.tabs--view {
  border-radius: 15px;
  margin: 1rem;
  box-shadow:
    0 0 20px 0 rgba(0, 0, 0, 0.2),
    0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.tabs {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.tab--btn {
  border-radius: 15px 15px 0 0;
  letter-spacing: 3px;
  border: none;
  padding: 10px;
  background-color: white;
  font-size: 1.2rem;
  cursor: pointer;
  transition: 0.5s;
  font-weight: bold;
}

.tab--btn:hover {
  background-color: #d5e3ff;
}

.tab--btn.active {
  background-color: #bccbe9;
}

.tab--content--wrapper {
  background-color: #bccbe9;
  border-radius: 0 0 15px 15px;
  padding: 1rem;
}

.tab--content {
  display: none;
}

.tab--content.active {
  display: block;
}

.tab--lessonStats {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-gap: 1rem;
  margin-top: 1rem;
}

.tab--average {
  width: 300px;
  margin: auto;
  border-radius: 15px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 1rem;
  padding: 10px;
  box-shadow:
    0 0 20px 0 rgba(0, 0, 0, 0.2),
    0 5px 5px 0 rgba(0, 0, 0, 0.24);
  background-color: white;
}

.tab--average .average {
  color: black;
  font-size: 1.2rem;
  text-align: left;
  font-weight: bold;
  align-self: center;
  text-align: center;
}

.tab--lessonStat {
  border-radius: 5px;
  box-shadow:
    0 0 20px 0 rgba(0, 0, 0, 0.2),
    0 5px 5px 0 rgba(0, 0, 0, 0.24);
  color: black;
  font-size: 1.2rem;
  background-color: white;
  padding: 0.5rem;
}

@media only screen and (max-width: 767px) {
  .key {
    font-size: 0.9rem;
  }

  .value {
    font-size: 0.8rem;
  }

  .lesson--name {
    font-size: 0.9rem;
  }
}

.table--container {
  margin: 0 1rem 0rem;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  grid-template-areas:
    'empty1 search empty2'
    'statstable statstable statstable';
  justify-items: center;
}

.category {
  font-size: 1.5rem;
  color: black;
  text-align: left;
  margin-left: 20px;
}

.category--tooltip {
  display: flex;
}

.tableHelper {
  font-size: 1.4rem;
}

@media only screen and (max-width: 767px) {
  .category {
    font-size: 1rem;
    margin-left: 0;
    text-align: center;
  }

  .category--tooltip {
    justify-content: center;
  }

  .tableHelper {
    font-size: 0.9rem;
  }
}

.expandable {
  box-shadow: 2px 5px black;
  background-color: white;
  border: 2px solid black;
  border-radius: 15px;
  margin: 1rem 2rem;
  padding: 0.5rem 0 1rem;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: transform var(--transition-duration), filter var(--transition-duration);
}

.expandable.active {
  /*Pale greenish blue color*/
  background:     #007BA7;
  cursor: default;
  transform: scale(var(--hover-scale));
  filter: brightness(var(--hover-brightness));
}

.expandable.active .category {
  margin-bottom: 1rem;
  color: white;
  
}

.expandable .admin--list {
  display: none;
}

.expandable .table--container {
  display: none;
}

.expandable.active .admin--list {
  display: grid;
}

.expandable.active .table--container {
  display: grid;
}

.expandable--toggle {
  background-color: linear-gradient(to right, rgb(74, 199, 128), #368a59, #008b82);
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  height: 20px;
  width: 20px;
}

.expandable--toggle .fa-times {
  display: none;
}

.expandable.active .expandable--toggle .fa-times {
  color: linear-gradient(to right, rgb(74, 199, 128), #368a59, #008b82);
  display: block;
}

.expandable.active .expandable--toggle .fa-chevron-down {
  display: none;
}

.expandable--toggle:focus {
  outline: 0;
}

.expandable.active .expandable--toggle {
  background-color: #9fa4a8;
}

.tableTip {
  text-align: left;
}

.empty1 {
  grid-area: empty1;
}

.empty2 {
  grid-area: empty2;
}

.search--bar {
  grid-area: search;
  font-size: 1rem;
  background-color: white;
  color: black;
  border-radius: 15px;
  padding: 5px 10px;
  display: flex;
}

.search--bar .fa-magnifying-glass {
  margin-right: 5px;
}

.search--bar input {
  outline: none;
  background-color: transparent;
  border: none;
}

.stats--table {
  grid-area: statstable;
  max-width: 100%;
}

.empty--message {
  position: fixed;
  top: 0;
  left: 0;
  color: black;
  font-size: 1.2rem;
  height: 100vh;
  width: 100vw;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}

.empty--message p {
  width: fit-content;
  height: fit-content;
}

.average1 {
  border-bottom: 1px solid #ddd;
}

.fa-circle-question {
  color: darkslategray;
}
