.missionStatementContainer {
  text-align: center;
  background: linear-gradient(rgba(210, 255, 190, 1) 0%, rgba(248, 248, 255, 1) 30%);
  backdrop-filter: blur(20px);
  border-top-left-radius: 400px;
  border-top-right-radius: 400px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
}

.misisonImage {
  width: 70%;
  border-radius: 20px;
  transition: all 0.5s;
}

.missionText {
  width: 70%;
}

.missionImportant {
  font-weight: 800;
}

.missionImportant:hover {
  text-decoration-color: #1b9a1b;
  color: #1b9a1b;
}

@media (min-width: 768px) {
  .misisonImage:hover {
    box-shadow: 0px 19px 0px rgb(80 213 26 / 97%);
    transform: scale(1.05) translateY(-20px);
  }
}

@media (max-width: 767px) {
  .missionStatementContainer {
    background-color: white;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .misisonImage {
    width: 100%;
  }

  .missionText {
    width: 100%;
    text-align: left;
  }
}
