.studentProjectsContainer {
  padding: 1rem;
  margin-top: 50px;
  text-align: center;
}

.studentProjectsListContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.studentProject {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
  border-radius: calc(20px + 1rem);
}

.studentProjectImage {
  width: 40%;
  border-radius: 20px;
  border: 1px solid black;
  transition: all 0.3s;
}

.studentProjectImage:hover {
  transform: scale(1.08);
}

.studentProjectTitle {
  padding: 1rem;
}

.studentProjectDescription {
  padding: 1rem;
  text-align: left;
}

@media (min-width: 768px) {
  .studentProject:nth-child(even) > img {
    order: 2;
  }
}

@media (max-width: 767px) {
  .studentProject {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
    border-radius: calc(20px + 1rem);
  }

  .studentProjectImage {
    width: 100%;
  }
}
