.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .modal-content {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    padding: 32px;
    width: 90%;
    max-width: 500px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .textarea {
    width: 100%;
    padding: 8px;
    font-family: inherit;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .textarea:focus {
    outline: none;
    border-color: #1976d2;
  }