.createContainer {
  text-align: center;
  background-color: white;
  backdrop-filter: blur(20px);
  border-radius: 20px;
  padding: 1rem;
  margin-top: 50px;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 2rem;
  justify-content: center;
  /* align-items: center; */
  text-align: center;
}

.image {
  width: 100%;
}

.grid1 {
  grid-column: span 2 / span 2;
  grid-row: span 5 / span 5;
  backdrop-filter: blur(20px);
  border-radius: 20px;
  padding: 1rem;
  background-color: #caafef;
  transition: all 0.3s;
}

.grid2 {
  grid-column: span 2 / span 2;
  grid-row: span 5 / span 5;
  grid-column-start: 3;
  backdrop-filter: blur(20px);
  border-radius: 20px;
  padding: 1rem;
  background-color: #ff80a0;
  transition: all 0.3s;
}

.grid3 {
  grid-column: span 2 / span 2;
  grid-row: span 5 / span 5;
  grid-column-start: 5;
  backdrop-filter: blur(20px);
  border-radius: 20px;
  padding: 1rem;
  background-color: #aadbff;
  transition: all 0.3s;
}

.grid4 {
  grid-column: span 4 / span 4;
  grid-row: span 2 / span 2;
  grid-column-start: 2;
  grid-row-start: 6;
  backdrop-filter: blur(20px);
  border-radius: 20px;
  padding: 1rem;
  background-color: #fffc76;
  transition: all 0.3s;
}

@media (min-width: 768px) {
  .grid2:hover,
  .grid4:hover {
    box-shadow: 0 5px 15px rgba(145, 92, 182, 0.4);
    transform: scale(1.05);
  }

  .grid1:hover {
    box-shadow: 0 5px 15px rgba(145, 92, 182, 0.4);
    transform: scale(1.05) rotate(-10deg);
  }

  .grid3:hover {
    box-shadow: 0 5px 15px rgba(145, 92, 182, 0.4);
    transform: scale(1.05) rotate(10deg);
  }
}

@media (max-width: 767px) {
  .createContainer {
    margin-top: 0px;
  }
  .gridContainer {
    grid-template-columns: 1fr; /* Single column layout */
    gap: 1rem; /* Adjust gap for smaller screens */
  }

  .grid1,
  .grid2,
  .grid3,
  .grid4 {
    grid-column: span 1 / span 1; /* Each grid item takes full width */
    grid-row: auto; /* Let the grid item occupy its natural row height */
    grid-row-start: auto;
  }
}
