body .portalContainer {
  max-width: unset;
  background-color: #e9f7e0;
  border-radius: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  height: 100vh;
  overflow-y: scroll;
}

h4 {
  color: #116811;
  text-align: center;
}

.formControl {
  background-color: white;
}

.inputLabel {
  color: #116811 !important;
}

.select {
  background-color: #ffffff;
  border-radius: 10px;
}

.radioGroup {
  justify-content: center;
}

.radioLabel {
  color: #116811;
}

body .buttonWrapper {
  display: flex;
  justify-content: center;
  margin: 1rem;
}

.list {
  background-color: #ceffd2;
  border-radius: 15px;
  padding: 15px !important;
  max-height: 60vh;
  overflow-y: auto;
  box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.list::-webkit-scrollbar {
  width: 10px;
}

.list::-webkit-scrollbar-track {
  background: #e0e0e0;
  border-radius: 10px;
}

.list::-webkit-scrollbar-thumb {
  background: #70c070;
  border-radius: 10px;
}

.list::-webkit-scrollbar-thumb:hover {
  background: #58a058;
}

.feedbackListItem {
  background-color: #ffffff;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.listItem {
  background-color: #ffffff;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.listItem:last-child {
  margin-bottom: 0;
}

.listItem:hover {
  background-color: #b0ffb0;
  cursor: pointer;
}

.listItemHeader {
  display: flex;
  justify-content: space-between;
}

.listItemName {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
}

.listItemFooter {
  display: flex;
  justify-content: flex-end;
}

.toggleButtonGroupContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.toggleButtonGroup {
  background-color: #abff76;
  border-radius: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

body .toggleButton {
  color: #116811;
  background-color: #ffffff;
  border: 1px solid #116811;
  border-radius: 10px;
  padding: 8px 16px;
  font-weight: 600;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

body .toggleButton:hover {
  background-color: #58a058;
  color: #ffffff;
}

@media (max-width: 600px) {
  .container {
    padding: 15px;
  }

  h4 {
    font-size: 1.75rem;
  }

  .list {
    max-height: 50vh;
  }

  body .toggleButton {
    padding: 6px 12px;
    font-size: 0.875rem;
  }

  .listItemName {
    flex-direction: column;
  }

  .listItemName h6 {
    white-space: unset;
  }
}
