body .header {
  border-radius: 20px;
  /* box-shadow: -1px 2px 6px #4a8d7b, 8px 9px 10px #4a8d7b; */
  /* box-shadow: 0px 3px 0px #4a8d7b, 0px 0px 0px #4a8d7b; */
  box-shadow:
    0px 0px 0px #4a8d7b,
    0px 0px 0px #4a8d7b;
  backdrop-filter: blur(40px);
  border: none;
  max-width: 1200px;
}

.header-buttons {
  display: flex;
  gap: 1rem;
  text-transform: capitalize;
}

.logo {
  height: 1.5rem;
  width: 4rem;
  cursor: pointer;
}

.logo-container {
  flex-grow: 1;
}
