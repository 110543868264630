.model_3d_container {
  padding-top: 40px;
}

@media (max-width: 767px) {
  .model_3d_container {
    display: flex;
    justify-content: center;
  }

  body canvas {
    width: 150% !important;
  }

  .model_3d {
    display: contents;
    overflow: clip !important;
    width: unset !important;
  }
}
