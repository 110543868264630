.partnersContainer {
  padding: 1rem;
  margin-top: 50px;
  text-align: center;
  backdrop-filter: blur(20px);
  background: linear-gradient(rgb(183 255 151) 0%, rgba(248, 248, 255, 1) 40%);
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partnersText {
  width: 70%;
  text-align: center;
  padding: 1rem;
}

.partnersListContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  padding: 1rem;
}

.partnerImage {
  width: 100px;
}

@media (max-width: 767px) {
  .partnersText {
    width: 100%;
    text-align: left;
  }
}
