/* Add animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* Apply animations */
.quiz {
  background-color: #1d1144;
  height: calc(100vh - 60px);
  color: white;
  font-family: 'Helvetica Neue', 'Source Sans Pro';
  overflow: auto;
  animation: fadeIn 1s ease-in-out;
}

.quiz-start {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  animation: slideIn 1s ease-in-out;
}

.quiz-questions {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: clamp(300px, 80%, 1000px);
  margin: 0 auto;
  animation: fadeIn 1s ease-in-out;
}

.quiz-result {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: clamp(300px, 80%, 1000px);
  margin: 0 auto 5vh;
  animation: fadeIn 1s ease-in-out;
}

.quiz-name {
  font-size: clamp(2rem, 3vw + 1rem, 50px);
  font-weight: 800;
  text-align: center;
  margin-bottom: 3vh;
}

.quiz-description {
  font-size: clamp(1.5rem, 2vw, 30px);
  margin-bottom: 3vh;
}

.quiz-startButton-container {
  justify-content: center;
  text-align: center;
}

.quiz-startButton,
.retake-button {
  background-color: #fff;
  border-radius: 12px;
  box-shadow:
    transparent 0 0 0 3px,
    rgba(18, 18, 18, 0.1) 0 6px 20px;
  box-sizing: border-box;
  color: #121212;
  cursor: pointer;
  display: inline-flex;
  flex: 1 1 auto;
  font-family: Inter, sans-serif;
  font-size: clamp(1.5rem, 2vw, 30px);
  font-weight: 700;
  line-height: 1;
  outline: none;
  padding: 1rem 1.2rem;
  text-align: center;
  text-decoration: none;
  transition:
    box-shadow 0.2s,
    -webkit-box-shadow 0.2s;
  white-space: nowrap;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.quiz-startButton:hover,
.retake-button:hover {
  background-color: #121212;
  color: #fff;
  box-shadow: aqua;
  animation: pulse 0.5s infinite;
}

.quiz-questionNumber {
  margin-top: 5vh;
  font-size: clamp(18px, 4vw, 28px);
}

.quiz-question {
  margin-top: 10vh;
  font-size: clamp(24px, 4vw, 36px);
}

.quiz-answerChoices {
  margin-top: 10vh;
  font-size: clamp(18px, 3vw, 24px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quiz-mc-ms {
  font-style: italic;
  font-size: clamp(12px, 2vw, 18px);
}

.quiz-answer {
  text-align: center;
  width: clamp(300px, 50%, 800px);
  background-color: #634af5;
  border-radius: 20px;
  margin-top: 1vh;
  font-weight: 700;
  padding: 1rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.quiz-answer:hover {
  background-color: #3413eb;
  animation: pulse 0.5s infinite;
}

.quiz-answer-selected {
  background-color: #3413eb;
  border: 5px solid white;
}

.quiz-nextQuestion-button-container {
  justify-content: center;
  text-align: center;
  margin-top: 5vh;
}

.quiz-nextQuestion-button {
  background-color: #fff;
  border-radius: 12px;
  box-shadow:
    transparent 0 0 0 3px,
    rgba(18, 18, 18, 0.1) 0 6px 20px;
  box-sizing: border-box;
  color: #121212;
  cursor: pointer;
  display: inline-flex;
  flex: 1 1 auto;
  font-family: Inter, sans-serif;
  font-size: clamp(1.5rem, 2vw, 30px);
  font-weight: 700;
  line-height: 1;
  outline: none;
  padding: 1rem 1.2rem;
  text-align: center;
  text-decoration: none;
  transition:
    box-shadow 0.2s,
    -webkit-box-shadow 0.2s;
  white-space: nowrap;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
}

.quiz-nextQuestion-button-hoverable:hover {
  background-color: #121212;
  color: #fff;
  box-shadow: aqua;
  animation: pulse 0.5s infinite;
}

.quiz-result-score {
  margin-top: 5vh;
  text-align: center;
  font-size: clamp(24px, 4vw, 36px);
  font-weight: 800;
  color: #ffcc00;
}

.quiz-result-question {
  margin-top: 5vh;
  font-size: clamp(1.5rem, 2vw, 30px);
  font-weight: 400;
  text-align: center;
}

.quiz-result-answers {
  margin-top: 2vh;
  font-size: clamp(18px, 3vw, 24px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quiz-result-answer {
  text-align: center;
  width: clamp(300px, 50%, 800px);
  background-color: #634af5;
  border-radius: 20px;
  margin-top: 1vh;
  font-weight: 700;
  padding: 1rem 1rem;
}

.quiz-result-correct {
  border: 5px solid rgb(133, 238, 133);
  background-color: rgba(133, 238, 133, 0.2);
}

.quiz-result-incorrect {
  border: 5px solid rgb(247, 59, 59);
  background-color: rgba(247, 59, 59, 0.2);
}

.quiz-result-explanation {
  font-size: clamp(12px, 2vw, 18px);
  margin-top: 2vh;
  text-align: center;
}

.fa-circle-check {
  color: rgb(133, 238, 133);
  padding-right: 1%;
}

.fa-circle-xmark {
  color: rgb(247, 59, 59);
  padding-right: 1%;
}

.retake-button {
  margin: 3rem auto 0;
  width: fit-content;
}