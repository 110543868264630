.feedback-form-container {
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  z-index: 99;
  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(10px);
  transition: backdrop-filter 0.3s ease-in-out;
}

.feedback-form {
  font-family: 'Inter', 'Source Sans Pro', 'sans-serif';
  background: #ffffff;
  color: #333;
  padding: 48px 48px;
  flex-direction: column;
  z-index: 100;
  display: flex;
  position: relative;
  border-radius: 16px;
  width: 60vw;
  box-shadow:
    0px 10px 30px rgba(0, 0, 0, 0.1),
    0px 20px 40px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.feedback-form h4 {
  color: #333;
  margin: 0;
  font-size: 2rem;
  font-weight: 900;
}

.reminder {
  color: #666;
  font-size: 1.25rem;
  font-weight: 300;
  margin: 0 0 24px 0;
  font-style: italic;
}

.feedback-form textarea {
  margin: 0;
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  resize: vertical;
  transition: border-color 0.3s ease-in-out;
}

.feedback-form textarea:focus {
  border-color: #8656f5;
  outline: 0;
}

.char-left,
.no-char-left {
  font-size: 1rem;
  margin: 8px 0 24px 0;
}

.no-char-left {
  color: red;
}

.feedback-form button {
  width: 100%;
  border: 0;
  border-radius: 8px;
  padding: 16px 32px;
  font-size: 1.25rem;
  font-weight: bold;
  cursor: pointer;
  outline: 0;
  transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.active {
  cursor: pointer;
  color: #ffffff;
  background: #8656f5;
}

.inactive {
  background: rgba(13, 9, 24, 0.2);
  color: #0d0918;
  cursor: not-allowed;
  pointer-events: none;
}

.feedback-form button:hover {
  opacity: 0.8;
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  opacity: 0.75;
  transition: opacity 0.3s ease-in-out;
}

.close:hover {
  opacity: 0.5;
}