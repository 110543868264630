.note {
  overflow-y: auto;
  font-family: "Roboto","Helvetica","Arial",sans-serif; 
  padding-left: 10px;
  padding-right: 10px;
  background-color: #e9f7e0; 
  border-radius: 15px; 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
}

.use-custom-scrollbar {
  height: calc(100vh - 4rem);
  border-radius: 15px; 
}

.note::-webkit-scrollbar {
  width: 10px;
}

.note::-webkit-scrollbar-track {
  background: #e0e0e0; 
  border-radius: 10px; 
}

.note::-webkit-scrollbar-thumb {
  background: #70c070; 
  border-radius: 10px;
}

.note::-webkit-scrollbar-thumb:hover {
  background: #58a058; 
}


.note img {
  width: 95%;
  border: 3px solid #a0a0ff; 
  border-radius: 10px; 
  margin: 10px 0; 
}

.note table {
  width: 100%; 
  border-collapse: collapse; 
  border-radius: 10px; 
  overflow: hidden; 
}

.note tr {
  border-top: 1px solid #c6cbd1;
  background: #ffffff;
}

.note thead {
  background-color: #a0a0ff; 
  color: #ffffff; 
}

.note th, .note td {
  padding: 8px 15px; 
  border: 1px solid #dfe2e5;
  text-align: center; 
}

.note td:hover {
  background-color: #e0f4e0; 
  text-decoration: underline; 
  text-decoration-color: #7d7dff; 
}

.note h1, .note h2, .note h3 {
  color: #116811; 
  text-align: center;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.note h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-decoration: underline;
}

.note h2 {
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
}

.note h3 {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}

/* Code blocks */
.note code {
  background-color: black;
  border: 1px solid #a0a0ff; 
  border-radius: 5px;
  padding: 2px 4px;
  font-size: 0.95rem;
}

/* Lists */
.note ul {
  border-radius: 15px;
  background-color: #e6f3ff;
  padding: 1.5rem;
  margin: 1rem 0;
}

.note ul li {
  font-weight: 500;
  line-height: 2rem;
  padding-left: 20px;
  transition: all 0.3s ease;
}

.note ul li::marker {
  content: '⚫️';
}

.note ul li:hover {
  background-color: #dceeff; 
  font-size: 105%;
  border-radius: 10px;
  padding-left: 25px;
}

.note ul li:hover::marker {
  content: '🟢';
}
