@media screen and (max-width: 500px) {
  .form {
    padding: 48px 16px !important;
    margin: 1rem;
  }
}

.doodle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw; 
  height: 100vh;
  z-index: -5;
  background-color: ghostwhite;
  display: flex;
}

.login {
  font-family: 'Inter', 'Source Sans Pro', 'sans-serif';
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

.login-content {
  position: absolute;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
}

.login__error {
  color: red;
}

.form {
  background: rgba(255, 255, 255);
  padding: 3rem;
  text-align: center;
  width: 440px;
  border-radius: 16px;
  box-shadow:
    0px 346px 97px 0px rgba(0, 0, 0, 0),
    0px 222px 89px 0px rgba(0, 0, 0, 0.01),
    0px 125px 75px 0px rgba(0, 0, 0, 0.05),
    0px 55px 55px 0px rgba(0, 0, 0, 0.09),
    0px 14px 30px 0px rgba(0, 0, 0, 0.1);
}

.loginText {
  padding-bottom: 2rem;
}

.form .pw-holder {
  width: 100%;
  border: 1px solid #0d0918;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin: 0 0 32px;
  padding: 12px 20px;
}

.focus {
  border: 1px solid #008b81 !important;
}

.form .pw-holder input {
  background: transparent;
  width: 100%;
}

.form .email {
  width: 100%;
  border: 1px solid #0d0918;
  border-radius: 16px;
  background: transparent;
  margin: 0 0 16px;
  padding: 12px 20px;
  box-sizing: border-box;
}

.form input {
  outline: 0;
  border: none;
  font-size: 1rem;
  padding: 0;
}

.form .pw-holder span {
  color: #0d0918;
  cursor: pointer;
  font-size: 1rem;
}

.login-button {
  color: #faf9ff;
  background: #368a59;
  transition: all 0.2s;
}

.login-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  background-color: rgb(74, 199, 128);
  color: #faf9ff;
}

.go-back-button {
  color: #0d0918;
  background: #e1e1e1;
  margin-top: 8px;
  transition: all 0.2s;
}

.form button {
  width: 100%;
  border: 0;
  border-radius: 16px;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  outline: 0;
}

.go-back-button:hover {
  background: rgba(13, 9, 24, 0.2);
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  color: #faf9ff;
}

.form .incorrent {
  color: red;
  font-size: 0.9rem;
  margin: 0 10px 15px;
}
