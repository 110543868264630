pre {
  position: relative;
  max-width: calc(100vw - 30px);
  margin-top: 0;    /* Remove top margin */
  margin-bottom: 0; /* Remove bottom margin */
}

.comment-code-block > code {
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 8px;
}

.code-line {
  display: inline-block !important;
  background: rgba(0, 0, 0, 0.9) !important;
  padding: 4px 6px !important;
  margin: 0 !important;
  border-radius: 8px;
}

.code-line code {
  padding: 0 !important;
  border: none !important;
  line-height: 16px;
  overflow: hidden;
}

.code-copy-icon {
  position: absolute;
  top: 4px;
  right: 1px;
  opacity: 0.5;
}

.code-copy-icon > button {
  background: none;
  border: none;
}

.popup {
  position: absolute;
  top: -4px;
  bottom: 20px;
  right: 20px;
  color: #fff;
  padding: 7px 20px;
  border-radius: 5px;
  opacity: 1;
  transition: opacity 0.5s ease-out;
}