.resetPassword {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  background-position: center;
}

.header-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.doodle {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}






.resetPassword::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgb(74, 199, 128), #368a59, #008b81);
  background-size: 200% 200%;
  animation: colorgradient 6s ease infinite;
}

@keyframes colorgradient {
  0% {
    background-position: 10% 0%;
  }

  50% {
    background-position: 91% 100%;
  }

  100% {
    background-position: 10% 0%;
  }
}

.resetPassword--content {
  position: relative;
  z-index: 100;
  width: 1400px;
  height: 100%;
  font-family: 'Helvetica Neue', 'Source Sans Pro';
  font-size: clamp(1.5rem, 2vw + 1rem, 40px);
  color: white;
  font-weight: 400;
  text-align: center;
  top: 125px;

}

.resetPassword__error {
  color: red;
}

.form-container {
  margin: 0 10px;
  position: relative;
  top: 50px;
}

.form {
  position: relative;
  z-index: 1;
  background: #fff;
  max-width: 360px;
  margin: 5vh auto;
  padding: 45px;
  text-align: center;
  box-shadow:
    0 0 20px 0 rgba(0, 0, 0, 0.2),
    0 5px 5px 0 rgba(0, 0, 0, 0.24);
  border-radius: 50px;
}

.pw-holder-focus {
  border: 2px solid blue !important;
}

.form .pw-holder {
  background: #fff;
  width: 100%;
  border: 1px solid dimgray;
  margin: 0 0 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 10px;
}

.form .pw-holder input {
  width: 100%;
}

.form .incorrent-pw {
  color: red;
  font-size: 0.9rem;
  margin: 0 1px 15px;
  position: relative;
  float: left;
}

.form input {
  outline: 0;
  border: none;
  font-size: 1rem;
  padding: 0;
}

.form span {
  color: blue;
  cursor: pointer;
  font-size: 0.9rem;
}

.form button {
  outline: 0;
  width: 100%;
  border: 0;
  border-radius: 20px;
  padding: 15px;
  color: #ffffff;
  font-size: 1rem;
  font-family: 'Helvetica Neue', 'Source Sans Pro';
}

.form-title{
  font-size: 25px;
  color: black;
  position: relative;
  float: left;
  padding: 0;
  font-weight: bold;
  margin: 0 0 15px;
}

.form .critera-container {
  display: inline-block;
  word-wrap: break-word;
  overflow-wrap: break-word;
  width: 100%;
  text-align: left;
  color: black;
  font-size: 15px;
  margin-top: 35px;
  display: none;

}

.form .critera-container p {
  display: inline-block;
  margin: 0; 
  padding-right: 4px;
}

.form .critera-container .normal-p {
  color: rgb(95, 95, 95);
}

.form .critera-container .disableInline-block {
  display: block;
}

.form .password-indicator-container {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  height: 25px;
  border: solid 3px rgba(97, 97, 97);
  border-radius: 10px;
  background-image: url("https://swc-static-images.s3.amazonaws.com/resetpassword/resetpassword_stripes.png");
  overflow: hidden;

}

.form .password-indicator {
  height: 100%;
  max-width: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-image: url("https://swc-static-images.s3.amazonaws.com/resetpassword/resetpassword_rainbow.png");
  background-repeat: no-repeat;
  transition: 1s;
  box-shadow: 5px 2px 3px rgb(102, 102, 102);
}

.form .strength {
  position: relative; 
  padding-right: 20px;
  margin: 0;
  padding: 0;
  color: rgb(123, 122, 122);
  font-size: 15px;
  float: right;
}



.form .green {
  color: #028a0f;
  font-size: 0.9rem;
  text-align: left;
  margin-bottom: 5px;
}

ul {
  list-style: none;
  padding-inline-start: 0;
}

ul li.green:before {
  content:"\2713\0020";
}

ul li.red:before {
  content: disc;
}

.form .red {
  color: red;
  font-size: 0.9rem;
  text-align: left;
  margin-bottom: 5px;
}

.enabled {
  cursor: pointer;
  background: #435db8;
}

.disabled {
  pointer-events: none;
  background: gray;
}

.enabled:hover {
  background: #5c7bcf;
}
