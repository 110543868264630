.popup {
  position: fixed;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3B3C36;
  color: white;
  padding: 15px;
  border-radius: 40px;
  overflow: hidden;
  z-index: 10000;
}

.popup .message {
  font-size: 1rem;
  font-family: "Helvetica Neue", "Source Sans Pro";
  align-self: center;
  white-space: nowrap;
}

.popup .checkmark {
  font-size: 2rem;
  color: #028A0F;
  align-self: center;
  margin-right: 10px;
}

.popup .xmark {
  font-size: 2rem;
  color: red;
  align-self: center;
  margin-right: 10px;
}

.countdown-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: transparent;
}

.progress {
  height: 100%;
  background-color: #C9C9C9;
  transition: width .1s ease-out;
}