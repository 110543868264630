.container {
  position: relative;
}

.bell {
  color: #002d62;
  font-size: 1.2rem;
  align-self: center;
  padding: 0.5rem;
  border-radius: 50px;
  cursor: pointer;
}

.bell.active {
  background-color: white;
}

.bell:hover {
  background-color: white;
}

.badge {
  position: absolute;
  top: -5px;
  right: -5px;
  padding: 3px 8px;
  border-radius: 50%;
  background: red;
  color: white;
  font-size: 1rem;
}

.notification-center {
  position: fixed;
  right: 10px;
  transform: translateY(20px);
  width: 500px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  font-family: 'Helvetica Neue', 'Source Sans Pro';
  font-weight: bold;
  text-align: center;
}

.triangle {
  position: absolute;
  transform: translateX(-3px);
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid white;
  z-index: 1;
}

.notifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 424px;
  padding: 10px;
}

.empty {
  color: black;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  margin: 50px 0;
}

.check {
  color: green;
  font-size: 5rem;
}

.empty span {
  text-align: center;
}

.notification {
  color: black;
  display: flex;
  font-size: 1rem;
  padding: 15px;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  background-color: rgb(59, 89, 152, 0.2);
  border-radius: 15px;
  margin: 8px;
}

.notification:hover {
  background-color: rgb(59, 89, 152, 0.3);
}

.notification.read {
  background-color: white;
  font-weight: lighter;
}

.notification.read:hover {
  background-color: rgba(192, 192, 192, 0.3);
}

.notification-header {
  display: flex;
  justify-content: space-between;
}

.replier {
  text-align: left;
  padding-bottom: 10px;
  color: darkblue;
}

.notification-body {
  display: flex;
  justify-content: space-between;
}

.content {
  text-align: left;
  padding-bottom: 10px;
  color: black;
}

.course-and-lesson-name {
  text-align: left;
  color: black;
  font-size: 0.8rem;
}

.time {
  color: #848482;
  font-size: 0.8rem;
}

.comment {
  color: #bd33a4;
  align-self: center;
}

.words {
  display: flex;
  flex-direction: column;
}

.mark-as-read {
  font-size: 0.8rem;
  color: dodgerblue;
  margin-left: 10px;
}

.notification-footer {
  display: flex;
  justify-content: space-between;
}

.top-bar {
  font-size: 0.8rem;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
}

.top-bar .title {
  color: black;
  font-size: 1.8rem;
}

.top-bar .mark-all-as-read {
  cursor: pointer;
  align-self: center;
  color: dodgerblue;
}

.top-bar .mark-all-as-read:hover,
.mark-as-read:hover {
  color: lightskyblue;
}

.bottom-bar {
  font-size: 0.8rem;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 15px;
}

.bottom-bar .show-hide {
  color: dodgerblue;
  cursor: pointer;
}

.bottom-bar .show-hide:hover {
  color: lightskyblue;
}

.notifications::-webkit-scrollbar {
  width: 10px;
}

.notifications::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 15px;
}

.notifications::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 15px;
}

.notifications::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Move the scrollbar to the corner */
.notifications::-webkit-scrollbar-corner {
  background: #f1f1f1;
  border-radius: 15px;
}

@media only screen and (max-width: 767px) and (min-width: 361px) {
  .notification-center {
    width: 360px;
  }
}

@media only screen and (max-width: 360px) {
  .notification-center {
    width: 330px;
  }
}
