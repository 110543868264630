.confirm--box--container {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 101;
}

.confirm--box {
  font-family: 'Inter', 'Source Sans Pro', 'sans-serif';
  background: #faf9ff;
  padding: 16px 32px;
  text-align: right;
  border-radius: 16px;
  z-index: 102;
}

.confirm--box h5 {
  color: black;
  font-size: 1.5rem;
  margin: 0 0 28px 0;
}

.confirm--box button {
  padding: 8px 16px;
  color: black;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 8px;
  border: 0;
}

.confirm--box .button-confirm {
  background: #ff5290;
  color: white;
}

.confirm--box .button-cancel {
  background: #ccc;
  margin-right: 16px;
}

.confirm--box button:hover {
  opacity: 0.8;
}

@media screen and (max-width: 767px) {
  .confirm--box button:hover {
    background-color: transparent;
  }
}
